import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax'; 
import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, HeadingWrapper, Title, Line, SubTitle, SolidBackground, TextureBackground, Container } from '../components/general/general'
import banner from '../images/index/banner.jpg';
import { palette } from "../styles/variables.js"
import { deviceMax } from "../styles/media"
import { useWindowSize } from "../components/general/window"


// Styled Components

const Background = styled.div`
    position: relative;
    z-index: -1;
    width: 100%;
    height: 40rem;
    background-image: url(${banner});
    background-size: cover;
    margin-bottom: -38rem;
 `;
const Overlay = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 15, 41, 0.8);
`;
// const NameWrapper = styled.div`
//     position: relative;
//     width: 100%;
//     margin-bottom: 4rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// `;
// 
const TitleAlt = styled(Title)`
    @media ${deviceMax.laptop} {
        font-size: 6rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 5rem;
    } 
`;
// const Line = styled.div`
//     width: 65%;
//     opacity: .8;
// `;
// const SubTitle = styled.h2`
//     font-family: "Raleway";
//     font-size: 3rem;
//     color: ${palette.text90};
//     text-transform: uppercase;
//     text-align: center;
//     max-width: 1072px;
//     white-space: break-spaces;
//     /* margin: 0;
//     letter-spacing: .2rem; */
// `;
const SubTitleAlt = styled(SubTitle)`
    @media ${deviceMax.laptopL} {
        font-size: 2.5rem;
    }
    @media ${deviceMax.laptop} {
        font-size: 2rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 1.5rem;
    } 
    
`;
// const SolidBackgroundAlt = styled(SolidBackground)`
//   &&& {
//     clip-path: polygon(0 0, 100% 2rem, 100% 100%, 0 100%);
//   }
// `;
// const Container = styled.div`
//     width: 1072px;
//     height: 100%;
//     margin-bottom: 6rem;
//     padding-top: 4rem;
// `;
const ContainerAlt = styled(Container)`
    margin-top: 2rem;
`;
const GoalCard = styled.div`
max-width: 1072px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
    padding: 2rem 1rem;
    background: #1F2239;
    border-radius: 5px;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    &.left {
        flex-direction: row;
    }
    &.right {
        flex-direction: row-reverse;
    }
    @media ${deviceMax.tablet} { 
        justify-content: center;
        align-items: center;
        
        &.left {
            flex-direction: column-reverse;
        }
        &.right {
            flex-direction: column-reverse;
        }
    }
    @media ${deviceMax.mobileL} {
        margin-bottom: 3rem;
        padding: 1rem .5rem;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
const Copy = styled.div`
    width: 65%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: break-spaces;
    /* border: 2px solid red; */
    @media ${deviceMax.laptop} { 
        width: auto;
    }
`;
const Images = styled.div`
    position: relative;
    width: 35%;
    padding: 0 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    @media ${deviceMax.laptop} { 
        width: auto;
        margin-bottom: 3rem;
    }
`;
const GoalTitle = styled.h3`
    font-family: "Raleway";
    font-size: 1.6rem;
    font-weight: 800;
    text-transform: uppercase;
    color: ${palette.text90};
`;
const GoalDescription = styled.p`
    font-family: "Alegreya Sans";
    font-size: 1.1rem;
    text-align: justify;
    text-justify: inter-word;
    color: ${palette.text90};
`;
const PrimaryImage = styled(props => <GatsbyImage {...props} />)`
    width: 16rem;
    height: 16rem;
    margin-bottom: -4rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid ${palette.warmWhite};
`;
const SecondaryImage = styled(props => <GatsbyImage {...props} />)`
    width: 11rem;
    height: 11rem;
    margin-bottom: -3rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid ${palette.warmWhite};
    &.left {
        margin-right: -4rem;
    }
    &.right {
        margin-left: -4rem;
    }
`;
const TertiaryImage = styled(props => <GatsbyImage {...props} />)`
    width: 8rem;
    height: 8rem;
    margin-left: -2rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid ${palette.warmWhite};
    &.left {
        margin-left: -2rem;
    }
    &.right {
        margin-right: -2rem;
    }
`;

const StoryPage = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    return (
        <Layout>
            <Head title="Our Story"/>
            <PageContainer>
                <ContentWrapper>
                    <ParallaxProvider>
                        <Parallax
                            y={[-50, 50]}
                            slowerScrollRate
                            disabled={width <= 1024 }
                        >
                            <Background>
                                <Overlay />
                            </Background>
                        </Parallax>
                        <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                        >
                            <HeadingWrapper>
                                <TitleAlt>{props.data.contentfulOurStory.title}</TitleAlt>
                                <Line>
                                    <StaticImage
                                        src="../images/lines/thick-line.png"
                                        alt="Single Heavy Weight Line"
                                        layout="constrained"
                                        // css={`
                                        //     width: 65%;
                                        //     opacity: .8;
                                        // `}                                       
                                    />
                                </Line>
                                <SubTitleAlt>{props.data.contentfulOurStory.subtitle.subtitle}</SubTitleAlt>
                            </HeadingWrapper>
                        </Parallax>
                        <Parallax disabled={width <= 1024 }>
                                <SolidBackground>
                                <TextureBackground>
                                <ContainerAlt>
                                    {props.data.contentfulOurStory.storys.map((story) => {
                                        return (
                                            <GoalCard className={story.theme}>
                                                <Copy>
                                                    <GoalTitle>{story.storyTitle}</GoalTitle>
                                                    <GoalDescription>{story.storyDescription.storyDescription}</GoalDescription>
                                                </Copy>
                                                <Images>
                                                    <PrimaryImage 
                                                        image={story.primaryImage.gatsbyImageData}
                                                        alt={story.primaryImage.description}
                                                        aspectRatio={1}
                                                        
                                                    />
                                                    <SecondaryImage className={story.theme}
                                                        image={story.secondaryImage.gatsbyImageData}
                                                        alt={story.secondaryImage.description}
                                                        aspectRatio={1}
                                                        
                                                    />
                                                    <TertiaryImage className={story.theme}
                                                        image={story.tertiaryImage.gatsbyImageData}
                                                        alt={story.tertiaryImage.description}
                                                        aspectRatio={1}
                                                        
                                                    />

                                                </Images>
                                            </GoalCard>
                                        )
                                    })}
                                </ContainerAlt>
                                </TextureBackground>
                            </SolidBackground>
                        </Parallax>
                    </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulOurStory {
            title
            subtitle {
                subtitle
            }
            storys {
                storyTitle
                storyDescription {
                    storyDescription
                }
                theme
                primaryImage {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                secondaryImage {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                tertiaryImage {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
            }
        }
    }
`

export default StoryPage